let baseApiUrl = '';
let apiQuercusUrl = '';
let apiParkingUrl = '';
let analyticsId = '';
let analytics4Id = '';
let appEnvironment = 'local';

export const jibeConfig = {
  customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID, 10),
  name: 'Mobile',
  clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID,
  secret: process.env.REACT_APP_JIBESTREAM_SECRET,
  venueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID
};

const hostname = window && window.location && window.location.hostname;

// eslint-disable-next-line no-console
console.log('h', hostname);

switch (true) {
  case hostname.includes('localhost') || hostname.includes('map-dev'): {
    baseApiUrl = 'http://localhost:3002';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-1'; // 'UA-162285914-1';
    analytics4Id = 'G-XL8J93E0V2';
    appEnvironment = 'local';
    break;
  }
  case hostname.includes('dev'): {
    baseApiUrl = 'https://api-dev.parauco.com';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-148492316-3';
    analytics4Id = 'G-XL8J93E0V2';
    appEnvironment = 'dev';
    break;
  }
  case hostname.includes('qa'): {
    baseApiUrl = 'https://api-qa.parauco.com';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-1';
    analytics4Id = 'G-CLRB5NHRX5';
    appEnvironment = 'qa';
    break;
  }
  case hostname.includes('uat'): {
    baseApiUrl = 'https://api-uat.parauco.com';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-2';
    analytics4Id = 'G-CLRB5NHRX5';
    appEnvironment = 'uat';
    break;
  }
  case hostname.includes('map.parauco'): {
    baseApiUrl = 'https://api.parauco.com';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-3';
    analytics4Id = 'G-V5J3P2F01Y';
    appEnvironment = 'prod';
    break;
  }
  default:
    break;
}

export default {
  baseApiUrl,
  apiQuercusUrl,
  apiParkingUrl,
  analyticsId,
  analytics4Id
};

export const environment = appEnvironment;
